<template>
  <section
    v-if="aggregates.length > 0 || combined.length > 0"
    class="pb-1 pt-3"
  >
    <div v-if="aggregates.length > 0" class="margin-top20">
      <h3 class="description">{{ $t("addAggregate") }}</h3>
      <v-list v-for="aggregate in aggregates" :key="aggregate.id">
        <ListItemTitleCategory v-if="aggregate.change" :item="aggregate" />
        <ItemAggregate :aggregate="aggregate" />
      </v-list>
    </div>

    <div v-if="combined.length > 0" class="margin-top20">
      <h3 class="description" v-if="combined[0].length > 0">
        {{ $t("addCombined") }}
      </h3>
      <ListCombined
        v-for="(combinedItem, index) in combined"
        :combined="combinedItem"
        :key="index"
      />
    </div>
  </section>
</template>
<script>
import { productsCtrl } from "@/controllers";
import { aggregatesStoreActions } from "@/store/modules/aggregates/constNames";
import { combinedStoreActions } from "@/store/modules/combined/constNames";

export default {
  name: "ListAggregatesCombined",
  components: {
    ItemAggregate: () =>
      import("@/components/aggregatesAndCombined/ListItemAggregates.vue"),
    ListCombined: () =>
      import("@/components/aggregatesAndCombined/ListCombined.vue"),
    ListItemTitleCategory: () => import("./ListItemTitleCategory.vue"),
  },
  data() {
    return {
      aggregates: [],
      combined: [],
    };
  },
  mounted() {
    const categoryType = JSON.parse(localStorage.getItem("categoryType"));
    if (!categoryType) return;

    const promises = categoryType.map(({ type, id }) => {
      if (type === "sin acompañantes") {
        return Promise.resolve({ type, items: [] });
      }

      return productsCtrl.getProductsByCategory(id).then((res) => {
        res[0] && (res[0].change = true);
        return { type, items: res };
      });
    });

    Promise.all(promises).then((res) => {
      res.forEach(({ type, items }) => {
        type === "aggregate" &&
          items.length > 0 &&
          (this.aggregates = [...this.aggregates, ...items]);
        type === "companion" &&
          items.length > 0 &&
          (this.combined = [...this.combined, items]);
      });
    });
  },
  destroyed() {
    this.$store.dispatch(aggregatesStoreActions.RESET_AGGREGATES);
    this.$store.dispatch(combinedStoreActions.RESET_COMBINED);
  },
};
</script>
